import Alpine from 'alpinejs';
import htmx from 'htmx.org';

import focus from '@alpinejs/focus';
import resize from '@alpinejs/resize';

Alpine.plugin(focus);
Alpine.plugin(resize);

window.Alpine = Alpine;
window.htmx = htmx;


if(nonce !== undefined) {
    document.addEventListener('htmx:configRequest', function(event) {
        event.detail.headers['X-WP-Nonce'] = nonce;
    });
}

document.addEventListener('DOMContentLoaded', function() {
    Alpine.start();
});

// Alpine.magic('blockData', (el, { Alpine }) => {
//     // el.parentElement.id is the key of the blockData object
//     let blockData = Alpine.store('blockData')[el.parentElement.id];
//     return blockData;
// });
